import { APIRequestWithAuth } from './config';

export const submitTransferEvent = async (body) => {
  // Add new keys with the correct casing that match the event schema and delete the old lowercase keys
  const updatedBody = {
    ...body, transferId: body.transferid, sourceAccount: body.sourceaccount, destinationAccount: body.destinationaccount, externalTransferId: body.externaltransferid,
  };
  delete updatedBody.transferid;
  delete updatedBody.sourceaccount;
  delete updatedBody.destinationaccount;
  delete updatedBody.externaltransferid;
  delete updatedBody.datetime;

  // eslint-disable-next-line no-console
  console.log('sending Transfer Event', updatedBody);
  const data = await APIRequestWithAuth('POST', '/v1/sendTransferEvent', updatedBody);
  if (data) {
    return data;
  }
  return null;
};
