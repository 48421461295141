/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import {
  Container, Row, Col, Card, CardBody, CardTitle, Spinner, Alert,
} from 'reactstrap';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import ReactTable from '../../Components/ReactTable/ReactTable';
import GroupInvestingService from '../../../Services/GroupInvestingService';
import GroupTransfersCreateModal from './GroupTransfersCreateModal';
import { submitTransferEvent } from '../../../Services/TransfersService';
import ActionCell from './ActionCell';

export default function GroupTransfersTable({ proposalId, groupId }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const toggleModal = useCallback(() => {
    setModalOpen((prev) => !prev);
    setErrorMessage('');
  }, []);

  const groupTransferEventsQuery = useQuery(
    ['groupTransfers', proposalId],
    () => GroupInvestingService.getGroupTransferEvents(proposalId),
    { keepPreviousData: true },
  );

  const groupAccountsQuery = useQuery(
    ['groupAccounts', proposalId],
    () => GroupInvestingService.getAllAccountsForGroup(groupId),
    { keepPreviousData: true },
  );

  const handleActionClick = useCallback(
    (row) => {
      setSelectedRow(row);
      toggleModal();
    },
    [toggleModal],
  );

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const result = await submitTransferEvent(formData);
        if (result) {
          groupTransferEventsQuery.refetch();
          toggleModal();
        } else {
          setErrorMessage('Failed to submit transfer event.');
        }
      } catch (error) {
        setErrorMessage('An error occurred while submitting the transfer event.');
      }
    },
    [groupTransferEventsQuery, toggleModal],
  );

  // Define the Cell component outside of render
  const renderActionCell = useCallback(
    ({ row }) => (
      <ActionCell row={row.original} handleActionClick={handleActionClick} />
    ),
    [handleActionClick],
  );

  // Memoize columns to prevent re-creation on every render
  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Amount', accessor: 'amount' },
      { Header: 'Timestamp', accessor: 'timestamp' },
      { Header: 'Transfer ID', accessor: 'transferid' },
      { Header: 'External Transfer ID', accessor: 'externaltransferid' },
      { Header: 'Type', accessor: 'type' },
      { Header: 'State', accessor: 'state' },
      { Header: 'DateTime', accessor: 'datetime' },
      { Header: 'Source Account', accessor: 'sourceaccount' },
      { Header: 'Destination Account', accessor: 'destinationaccount' },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: renderActionCell,
      },
    ],
    [renderActionCell],
  );

  // eslint-disable-next-line no-console
  console.log('groupAccountsQuery');
  // eslint-disable-next-line no-console
  console.log(groupAccountsQuery.data);

  return (
    <Container fluid className="mt-4">
      <div style={{ marginLeft: '18%', marginRight: '5%' }}>
        <Row className="justify-content-center">
          <Col xs={12} md={12}>
            {groupTransferEventsQuery.isLoading && <Spinner color="primary" />}
            {groupTransferEventsQuery.isError && (
              <Alert color="danger">Error fetching data</Alert>
            )}
            {groupTransferEventsQuery.isSuccess
              && groupTransferEventsQuery.data && (
                <Card className="mb-4">
                  <CardBody>
                    <CardTitle tag="h2">Group Transfer Events</CardTitle>
                    <ReactTable
                      isSearchActive
                      tableRoute="groups"
                      data={
                        Array.isArray(groupTransferEventsQuery.data)
                          ? groupTransferEventsQuery.data
                          : []
                      }
                      columns={columns}
                    />
                  </CardBody>
                </Card>
            )}
          </Col>
        </Row>

        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

        {/* Pass destinationAccounts prop to the modal */}
        <GroupTransfersCreateModal
          isOpen={modalOpen}
          toggle={toggleModal}
          row={selectedRow}
          onSubmit={handleSubmit}
          destinationAccounts={groupAccountsQuery.isSuccess ? groupAccountsQuery.data : []}
        />
      </div>
    </Container>
  );
}

GroupTransfersTable.propTypes = {
  proposalId: PropTypes.string.isRequired,
};
