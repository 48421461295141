/* eslint-disable import/no-unresolved */
/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import './FinancialBackground.css'; // We'll create this CSS file next

const FinancialBackground = () => {
  const tickerRef = useRef(null);
  const chartRef = useRef(null);
  const indicatorsRef = useRef(null);

  useEffect(() => {
    // Initialize Ticker
    const ticker = tickerRef.current;
    const stocks = [
      { symbol: 'AAPL', price: 150.12 },
      { symbol: 'GOOGL', price: 2750.65 },
      { symbol: 'AMZN', price: 3400.50 },
      { symbol: 'MSFT', price: 299.01 },
      { symbol: 'TSLA', price: 720.30 },
      { symbol: 'FB', price: 355.22 },
      { symbol: 'NFLX', price: 590.45 },
      { symbol: 'NVDA', price: 220.75 },
      { symbol: 'JPM', price: 160.80 },
      { symbol: 'V', price: 230.55 },
    ];

    const generateTickerContent = () => {
      let content = '';
      stocks.forEach((stock) => {
        const change = (Math.random() * 10 - 5).toFixed(2); // Simulate price change
        const changeSign = change >= 0 ? '+' : '';
        content += `<span class="ticker-item">${stock.symbol}: $${stock.price.toFixed(2)} (${changeSign}${change})</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
      });
      ticker.innerHTML = content;
    };

    generateTickerContent();

    // Initialize Charts
    const ctx = chartRef.current.getContext('2d');
    const chartWidth = chartRef.current.width;
    const chartHeight = chartRef.current.height;

    let chartData = Array(100).fill(200 + Math.random() * 100);

    const drawChart = () => {
      ctx.clearRect(0, 0, chartWidth, chartHeight);
      ctx.beginPath();
      ctx.moveTo(0, chartData[0]);

      for (let i = 1; i < chartData.length; i++) {
        ctx.lineTo((i / chartData.length) * chartWidth, chartData[i]);
      }

      ctx.strokeStyle = '#00FF00';
      ctx.lineWidth = 2;
      ctx.stroke();
    };

    drawChart();

    const updateChart = () => {
      chartData.shift();
      chartData.push(200 + Math.random() * 100);
      drawChart();
    };

    const chartInterval = setInterval(updateChart, 1000); // Update every second

    // Initialize Indicators
    const indicators = indicatorsRef.current;
    const updateIndicators = () => {
      indicators.innerHTML = '';
      stocks.forEach((stock) => {
        const change = (Math.random() * 10 - 5).toFixed(2);
        let status = 'Stable';
        let color = '#FFD700'; // Gold for stable

        if (change > 0) {
          status = 'Up';
          color = '#32CD32'; // LimeGreen for up
        } else if (change < 0) {
          status = 'Down';
          color = '#FF4500'; // OrangeRed for down
        }

        indicators.innerHTML += `
          <div class="indicator-item">
            <span class="indicator-symbol">${stock.symbol}</span>
            <span class="indicator-status" style="color: ${color};">${status}</span>
          </div>
        `;
      });
    };

    updateIndicators();
    const indicatorsInterval = setInterval(updateIndicators, 2000); // Update every 2 seconds

    return () => {
      clearInterval(chartInterval);
      clearInterval(indicatorsInterval);
    };
  }, []);

  return (
    <div className="financial-background">
      {/* Ticker Banner */}
      <div className="ticker-container">
        <div className="ticker" ref={tickerRef}></div>
      </div>

      {/* Stock Chart */}
      <canvas className="stock-chart" ref={chartRef} width="800" height="200"></canvas>

      {/* Market Indicators */}
      <div className="indicators-container" ref={indicatorsRef}></div>
    </div>
  );
};

export default FinancialBackground;
