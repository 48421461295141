/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ActionCell = React.memo(({ row, handleActionClick }) => (
  <div className="actions-right">
    <Button color="primary" onClick={() => handleActionClick(row)}>
      <i className="tim-icons icon-double-right" />
    </Button>
  </div>
));

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
  handleActionClick: PropTypes.func.isRequired,
};

export default ActionCell;
