/* eslint-disable import/no-unresolved */
/* eslint-disable no-alert */
/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Authenticator,
  useAuthenticator,
  ThemeProvider,
  createTheme,
  useTheme,
  View,
  Image,
  Heading,
  Button,
} from '@aws-amplify/ui-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AuthenticationService from 'Services/Auth.service';
import { updateSession } from 'store/Reducers/session';
import '@aws-amplify/ui-react/styles.css'; // Import Amplify UI styles
import '../../assets/css/hedgeStyle.css'; // Import custom CSS for alignment and background
import SweetAlert from 'react-bootstrap-sweetalert'; // Consider replacing with a more maintained library
import FinancialBackground from './FinancialBackground'; // Import the FinancialBackground component
import hedgeLogo from '../../assets/img/hedge-logo-gradient.png'; // Import the logo image

const customTheme = createTheme({
  name: 'custom-theme',
  tokens: {
    colors: {
      background: {
        primary: { value: '#121212' }, // Dark background
        secondary: { value: '#1e1e1e' }, // Slightly lighter dark background
      },
      font: {
        primary: { value: '#ffffff' }, // White text
        secondary: { value: '#e0e0e0' }, // Slightly lighter white text
      },
      brand: {
        primary: {
          10: { value: '#ff9900' }, // Your primary brand color
          100: { value: '#ff9900' }, // Your primary brand color
        },
      },
    },
    components: {
      button: {
        primary: {
          backgroundColor: { value: '#19E421' }, // Primary button background color
          color: { value: '#121212' }, // Primary button text color
        },
      },
    },
  },
});

// Define custom components for the Authenticator
const componentsConfig = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Company logo"
          src={hedgeLogo} // Use the imported logo image
          width="200px"
        />
      </View>
    );
  },
  Footer() {
    const { route, actions } = useAuthenticator();

    return (
      <View textAlign="center">
        {route !== 'forgotPassword' && (
          <Button
            fontWeight="normal"
            onClick={() => actions.forgotPassword()}
            size="small"
            variation="link"
            aria-label="Reset Password"
          >
            Reset Password
          </Button>
        )}
      </View>
    );
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Sign in to your Hedge account
        </Heading>
      );
    },
    Footer() {
      // Optional: You can define a custom footer for SignIn if needed
      return null;
    },
  },
};

export default function LandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totpSetupUri, setTotpSetupUri] = useState(null);
  const [totpCode, setTotpCode] = useState('');
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [alert, setAlert] = useState(null); // SweetAlert state

  // Define hideAlert before it's used
  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    const handleSignIn = async () => {
      if (user) {
        try {
          const { nextStep } = user.challengeName
            ? await AuthenticationService.handleSignIn({
              email: user.username,
              password: user.signInUserSession.idToken.jwtToken,
            })
            : { nextStep: null };

          if (nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') {
            return;
          }

          if (nextStep?.signInStep === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP') {
            const setupUri = await AuthenticationService.enableTOTP();
            setTotpSetupUri(setupUri);
            return;
          }

          dispatch(updateSession());
          const lastPath = localStorage.getItem('lastPath') || '/admin/dashboard';
          navigate(lastPath);
        } catch (error) {
          console.error('Error during sign-in:', error);
          setAlert(
            <SweetAlert
              danger
              title="Sign-In Failed"
              onConfirm={hideAlert}
              confirmBtnText="Retry"
              aria-label="Sign-In Error Alert"
            >
              There was an error signing in. Please try again.
            </SweetAlert>,
          );
        }
      }
    };

    handleSignIn();
  }, [user, dispatch, navigate]);

  const handleTotpSubmit = async () => {
    try {
      await AuthenticationService.verifyTOTP(totpCode);
      setTotpSetupUri(null);
      dispatch(updateSession());
      const lastPath = localStorage.getItem('lastPath') || '/admin/dashboard';
      navigate(lastPath);
    } catch (error) {
      console.error('Error verifying TOTP: ', error);
      setAlert(
        <SweetAlert
          danger
          title="Verification Failed"
          onConfirm={handleTotpSubmit} // Retry the TOTP verification
          confirmBtnText="Retry"
          cancelBtnText="Cancel"
          showCancel
          onCancel={hideAlert}
          aria-label="TOTP Verification Error Alert"
        >
          There was an error verifying your TOTP code. Please try again.
        </SweetAlert>,
      );
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div className="landing-page-container">
        {/* Financial Background */}
        <FinancialBackground />

        <div className="authenticator-container" role="main">
          <Authenticator components={componentsConfig} hideSignUp>
            {({ user: authUser }) => {
              if (authUser) {
                if (totpSetupUri) {
                  return (
                    <div className="totp-setup-container">
                      <h1>Set up TOTP</h1>
                      <p>Scan this QR code with your authenticator app:</p>
                      <img
                        src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                          totpSetupUri,
                        )}`}
                        alt="TOTP QR Code"
                      />
                      <input
                        type="text"
                        value={totpCode}
                        onChange={(e) => setTotpCode(e.target.value)}
                        placeholder="Enter TOTP Code"
                        aria-label="Enter TOTP Code"
                      />
                      <button onClick={handleTotpSubmit} aria-label="Submit TOTP Code">
                        Submit
                      </button>
                    </div>
                  );
                }

                return (
                  <main>
                    <h1>
                      Hello,
                      <br />
                      {authUser.username}
                    </h1>
                    <button onClick={signOut} aria-label="Sign out">
                      Sign out
                    </button>
                  </main>
                );
              }

              return null;
            }}
          </Authenticator>
        </div>

        {/* SweetAlert for Error Handling */}
        {alert}
      </div>
    </ThemeProvider>
  );
}
