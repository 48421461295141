/* eslint-disable react/prop-types */
import React from 'react';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';

function GroupTransfersCreateModal({
  isOpen, toggle, row, onSubmit, destinationAccounts,
}) {
  const [editedState, setEditedState] = React.useState('');
  const [selectedDestinationAccount, setSelectedDestinationAccount] = React.useState('');
  const [error, setError] = React.useState(null);

  // Initialize state when row changes
  React.useEffect(() => {
    if (row) {
      setEditedState(row.state || '');
      setSelectedDestinationAccount(row.destinationaccount || '');
      setError(null);
    }
  }, [row]);

  const handleStateChange = (e) => {
    setEditedState(e.target.value);
  };

  const handleDestinationAccountChange = (e) => {
    setSelectedDestinationAccount(e.target.value);
  };

  const handleSubmit = () => {
    if (!editedState) {
      setError('Please select a state before submitting.');
      return;
    }
    if (!selectedDestinationAccount) {
      setError('Please select a destination account before submitting.');
      return;
    }

    // Additional Validation
    if (
      selectedDestinationAccount === 'TEMP_STAGING_ACCOUNT'
      && !['Staged', 'Unstaged'].includes(editedState)
    ) {
      setError('When using TEMP_STAGING_ACCOUNT, only "Staged" or "Unstaged" states are allowed.');
      return;
    }

    setError(null);
    // eslint-disable-next-line no-console
    console.log('submitting', {
      ...row,
      state: editedState,
      destinationaccount: selectedDestinationAccount,
    });
    onSubmit({
      ...row,
      state: editedState,
      destinationaccount: selectedDestinationAccount,
    });
    toggle();
  };

  // Compute filtered destination accounts based on apex_user_id of sourceaccount
  const filteredDestinationAccounts = React.useMemo(() => {
    if (!row || !destinationAccounts || !Array.isArray(destinationAccounts)) return [];

    // Find the source account object based on sourceaccount's account_num
    const sourceAccount = destinationAccounts.find((acc) => acc.account_num === row.sourceaccount);
    if (!sourceAccount) {
      // eslint-disable-next-line no-console
      console.error(`Source account with account_num ${row.sourceaccount} not found in destinationAccounts.`);
      return [];
    }
    const sourceApexUserId = sourceAccount.apex_user_id;

    // Filter destination accounts to include only those with the same apex_user_id (e.g only accounts owned by the same user and associated with the same group)
    // Also exclude the sourceaccount from being listed as a destination account
    return destinationAccounts
      .filter((acc) => acc.apex_user_id === sourceApexUserId && acc.account_num !== row.sourceaccount);
  }, [row, destinationAccounts]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit and Confirm Transaction</ModalHeader>
      <ModalBody>
        {row ? (
          <div className="modal-content">
            <p>
              <strong>ID:</strong>
              {' '}
              {row.id}
            </p>
            <p>
              <strong>Amount:</strong>
              {' '}
              {row.amount}
            </p>
            <p>
              <strong>Timestamp:</strong>
              {' '}
              {row.timestamp}
            </p>
            <p>
              <strong>Transfer ID:</strong>
              {' '}
              {row.transferId}
            </p>
            <p>
              <strong>External Transfer ID:</strong>
              {' '}
              {row.externaltransferid}
            </p>
            <p>
              <strong>Type:</strong>
              {' '}
              {row.type}
            </p>
            <p>
              <strong>State:</strong>
              <select
                name="state"
                value={editedState}
                onChange={handleStateChange}
              >
                <option value="" disabled>Select a state</option>
                <option value="RejectedValidationFailed">Rejected Validation Failed</option>
                <option value="ReviewPending">Review Pending</option>
                <option value="RejectedUnapproved">Rejected Unapproved</option>
                <option value="RejectedPositionNotPosted">Rejected Position Not Posted</option>
                <option value="Staged">Staged</option>
                <option value="Unstaged">Unstaged</option>
                <option value="Submitted">Submitted</option>
                <option value="PositionPosted">Position Posted</option>
                <option value="Completed">Completed</option>
                <option value="Returned">Returned</option>
                <option value="Canceled">Canceled</option>
                <option value="Postponed">Postponed</option>
                <option value="FundsPosted">Funds Posted</option>
                <option value="Purgatory">Purgatory</option>
                <option value="OnDeck">On Deck</option>
                <option value="Resubmitted">Resubmitted</option>
                <option value="Branched">Branched</option>
                <option value="SUB_IN_PENDING">SUB IN PENDING</option>
                <option value="SUB_IN">SUB IN</option>
              </select>
            </p>
            <p>
              <strong>DateTime:</strong>
              {' '}
              {row.datetime}
            </p>
            <p>
              <strong>Source Account:</strong>
              {' '}
              {row.sourceaccount}
            </p>
            <p>
              <strong>Destination Account:</strong>
              {' '}
              <select
                name="destinationaccount"
                value={selectedDestinationAccount}
                onChange={handleDestinationAccountChange}
              >
                <option value="" disabled>Select a destination account</option>
                {/* Add TEMP_STAGING_ACCOUNT option */}
                <option value="TEMP_STAGING_ACCOUNT">TEMP_STAGING_ACCOUNT</option>
                {filteredDestinationAccounts.map((account) => (
                  <option key={account.account_id} value={account.account_num}>
                    {account.account_num}
                  </option>
                ))}
              </select>
            </p>
            <p>
              <strong>Action:</strong>
              {' '}
              {row.action}
            </p>
            <button type="button" onClick={handleSubmit}>Submit</button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </div>
        ) : (
          <p>No details available</p>
        )}
      </ModalBody>
    </Modal>
  );
}

export default GroupTransfersCreateModal;
