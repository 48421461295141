/* eslint-disable no-console */
import { APIRequestWithAuth } from './config';

/**
 * Requeue an event by its ID and event type
 * @param {string} eventId - ID of the event to requeue
 * @param {string} eventType - Type of the event to requeue
 * @returns {Promise<any>}
 */
export const requeueEvent = async (eventId, eventType) => {
  try {
    // Send POST request to the backend with both eventId and eventType
    const payload = { eventId, eventType };
    const response = await APIRequestWithAuth('POST', '/admin/events/requeue', payload);

    // Check if the response has a status field and if it's not 'success'
    if (!response || response.status !== 'success') {
      throw new Error(`Failed to requeue event. Response: ${JSON.stringify(response)}`);
    }

    // Log the successful requeue operation
    console.log('Requeue Response:', response);

    // Return the requeue details
    return response;
  } catch (error) {
    console.error('Error requeuing event:', error);
    throw error; // Rethrow the error to allow react-query to catch it
  }
};

/**
 * Fetch all unmatched events
 * @returns {Promise<Array>}
 */
export const getUnmatchedEvents = async () => {
  try {
    const data = await APIRequestWithAuth('GET', '/admin/events/unmatched');
    console.log('Unmatched Events Data:', data);
    return Array.isArray(data) ? data : [];
  } catch (error) {
    console.error('Error fetching unmatched events:', error);
    throw error; // Rethrow the error to allow react-query to catch it
  }
};

/**
 * Fetch all accounts reconciliation data
 * @returns {Promise<any>}
 */
export const getAllAccountsReconciliation = async () => {
  try {
    const data = await APIRequestWithAuth('GET', '/v1/getAccountsReconciliation');
    return data;
  } catch (error) {
    console.error('Error fetching accounts reconciliation data:', error);
    throw error;
  }
};

/**
 * Fetch SOD data
 * @param {string} date - Date for which to fetch SOD data
 * @returns {Promise<any>}
 */
export const getApexSOD = async (date) => {
  try {
    const data = await APIRequestWithAuth('GET', `/v1/getExt765File/${date}`);
    return data;
  } catch (error) {
    console.error('Error fetching Apex SOD data:', error);
    throw error;
  }
};

/**
 * Trigger manual reconciliation
 * @param {string} eventType - Type of the event to reconcile
 * @returns {Promise<any>}
 */
export const manualReconciliation = async (eventType) => {
  try {
    const payload = { eventType };
    const data = await APIRequestWithAuth('POST', '/admin/reconcile/manual', payload);
    return data;
  } catch (error) {
    console.error('Error triggering manual reconciliation:', error);
    throw error;
  }
};

/**
 * Fetch reconciliation logs
 * @returns {Promise<any>}
 */
export const getReconciliationLogs = async () => {
  try {
    const data = await APIRequestWithAuth('GET', '/admin/logs/reconciliation');
    return data;
  } catch (error) {
    console.error('Error fetching reconciliation logs:', error);
    throw error;
  }
};

/**
 * Fetch reconciliation statistics
 * @returns {Promise<any>}
 */
export const getReconciliationStats = async () => {
  try {
    const data = await APIRequestWithAuth('GET', '/admin/stats/reconciliation');
    return data;
  } catch (error) {
    console.error('Error fetching reconciliation stats:', error);
    throw error;
  }
};

/**
 * Retry a failed reconciliation event
 * @param {string} eventId - ID of the failed event to retry
 * @returns {Promise<any>}
 */
export const retryFailedEvent = async (eventId) => {
  try {
    const payload = { eventId };
    const data = await APIRequestWithAuth('POST', '/admin/reconcile/retry', payload);
    return data;
  } catch (error) {
    console.error('Error retrying failed event:', error);
    throw error;
  }
};

/**
 * Fetch event details by event ID
 * @param {string} eventId - ID of the event
 * @returns {Promise<any>}
 */
export const getEventDetails = async (eventId) => {
  try {
    const data = await APIRequestWithAuth('GET', `/admin/events/${eventId}`);
    return data;
  } catch (error) {
    console.error(`Error fetching details for event ${eventId}:`, error);
    throw error;
  }
};

/**
 * Fetch event types
 * @returns {Promise<any>}
 */
export const getEventTypes = async () => {
  try {
    const data = await APIRequestWithAuth('GET', '/admin/event-types');
    return data;
  } catch (error) {
    console.error('Error fetching event types:', error);
    throw error;
  }
};
